@import "../../constants";
@import "../../MainText";

#contact_us_form {
    .form_input {
        @extend .main_text;
    }
}

#login_form {
    .form_input {
        @extend .main_text;
    }
}
.form_input_container {
    padding-bottom: .7rem;
    width: 100%;
}
.form_validation_and_add_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: .5rem;
}
.form_add_new {
    .button {
        border: none;
        color: white;
        text-decoration: underline;
        background-color: transparent;
        box-shadow: none;
        &:hover {
            background-color: transparent;
            box-shadow: none;
            filter: $shadow;
            text-decoration-thickness: 3px;
        }
    }
}

.form_container {
    width: calc(100% - 1rem);
    div {
        outline-color: rgba(255, 255, 255, 0.6);
    }
}
.form_input_label {
    font-size: 16px;
}
.form_input {
    height: 36px;
    font-size: 17px;
    background-color: rgba(196, 196, 196, 0.18);
    border: 0px solid;
    border-bottom: 2px solid white;
    padding: 0.3rem .5rem .2rem .5rem;
    font-family: Arial;
    &:hover {
        background-color: rgba(196, 196, 196, 0.05);
    }
    &:hover, &:focus {
        border-color: $main_highlight;
    }
    &:focus {
        outline: 0.5px solid $main_highlight;
        border-color: $main_highlight;
        border-bottom: 1.5px solid $main_highlight;
    }
}
.react-datepicker-wrapper {
    width: 100%;
}
textarea {
    resize: none;
    overflow: hidden;
    min-height: 120px;
}

@media only screen and (min-width:450px) {
    .form_input_container {
        padding-bottom: .8rem;
    }
    .form_container {
        width: 20rem;
    }
}
@media only screen and (min-width:50em) {
    .form_input_container {
        display: flex;
        align-items: center;
    }
    .form_input_label {
        padding-right: 2rem;
        width: 10vw;
        text-align: right;
    }
}
@media only screen and (min-width:1058px) {
    .form_container {
        width: 30vw;
        max-width: 30vw;
    }
}