@import "../../constants";

.hamburger_icon {
    display: inline-block;
    cursor: pointer;
    padding: .5rem;
    border-radius: 0.25rem;
    z-index: 100;
    margin-right: .5rem;
}

.hamburger_line {
    width: 35px;
    height: 5px;
    background-color: #ffffff;
    margin: 6px 0;
    transition: 0.4s;
    border-radius: 1rem
}

/* Rotate first bar */
.open .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px) ;
    transform: rotate(-45deg) translate(-9px, 6px) ;
}
  
  /* Fade out the second bar */
.open .bar2 {
    opacity: 0;
}
  
  /* Rotate last bar */
.open .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
    transform: rotate(45deg) translate(-8px, -8px) ;
}

@media only screen and (min-width:$menu_collapse_width) {
    .hamburger_icon {
        display: none;
    }
}