.footer {
  text-align: center;
  padding: 1rem 0;
}

:focus {
  outline: none;
}

body {
  background-color: #676f76;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

html {
  -webkit-tap-highlight-color: transparent;
  font-family: sans-serif;
}

.app_no_footer {
  min-height: calc(100vh - 53px);
  flex-direction: column;
  display: flex;
}

.hidden {
  display: none;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.text_white {
  color: #fff;
}

.position_relative {
  position: relative;
}

.main_body {
  background-color: #e8e5e5;
  flex: 1;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
}

::-webkit-input-placeholder {
  color: rgba(255, 255, 255, .6);
}

::-ms-input-placeholder {
  color: rgba(255, 255, 255, .6);
}

::placeholder {
  color: rgba(255, 255, 255, .6);
}

:-ms-input-placeholder {
  color: rgba(255, 255, 255, .6);
}

::-moz-placeholder {
  color: rgba(255, 255, 255, .6);
}

.summary_container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #b8b8b8;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #7c7c7c;
}

.main_text {
  font-size: 20px;
}

@media only screen and (min-width: 450px) {
  .main_text {
    font-size: 25px;
  }
}

@media only screen and (min-width: 1350px) {
  .main_text {
    font-size: 30px;
  }
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon:before {
  content: "";
  height: 9px;
  width: 9px;
  border: 3px solid #ccc;
  border-width: 3px 3px 0 0;
  display: block;
  position: absolute;
  top: 6px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  width: 0;
  margin-left: -4px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  box-sizing: content-box;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border: 8px solid rgba(0, 0, 0, 0);
  position: absolute;
  left: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  margin-top: -8px;
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:after {
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: #aeaeae;
  top: -1px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  margin-bottom: -8px;
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after {
  border-top-color: #fff;
  border-bottom: none;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:after {
  bottom: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before {
  border-top-color: #aeaeae;
  bottom: -1px;
}

.react-datepicker-wrapper {
  width: 100%;
  border: none;
  padding: 0;
  display: inline-block;
}

.react-datepicker {
  color: #000;
  background-color: #fff;
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  font-family: Helvetica Neue, helvetica, arial, sans-serif;
  font-size: .8rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: none;
}

.react-datepicker--time-only .react-datepicker__time, .react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
  padding-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  padding-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  padding-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: .3rem;
  padding: 8px 0;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: .3rem;
}

.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll {
  margin: 0 2px;
  display: inline-block;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #000;
  margin-top: 0;
  font-size: .944rem;
  font-weight: bold;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  text-align: center;
  cursor: pointer;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  position: absolute;
  top: 2px;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 2px;
}

.react-datepicker__navigation--next {
  right: 2px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}

.react-datepicker__navigation--years {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
}

.react-datepicker__navigation:hover :before {
  border-color: #a6a6a6;
}

.react-datepicker__navigation-icon {
  width: 0;
  font-size: 20px;
  position: relative;
  top: -1px;
}

.react-datepicker__navigation-icon--next {
  left: -2px;
}

.react-datepicker__navigation-icon--next:before {
  left: -7px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.react-datepicker__navigation-icon--previous {
  right: -2px;
}

.react-datepicker__navigation-icon--previous:before {
  right: -7px;
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__year-wrapper {
  max-width: 180px;
  flex-wrap: wrap;
  display: flex;
}

.react-datepicker__year .react-datepicker__year-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__month {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  text-align: left;
  margin: 5px 0 10px 15px;
}

.react-datepicker__input-time-container .react-datepicker-time__caption, .react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  margin-left: 10px;
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button, .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  width: 85px;
  border-left: 1px solid #aeaeae;
}

.react-datepicker__time-container--with-today-button {
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  display: inline;
  position: absolute;
  top: 0;
  right: -72px;
}

.react-datepicker__time-container .react-datepicker__time {
  background: #fff;
  border-bottom-right-radius: .3rem;
  position: relative;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  text-align: center;
  border-bottom-right-radius: .3rem;
  margin: 0 auto;
  overflow-x: hidden;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  height: calc(195px + .85rem);
  width: 100%;
  box-sizing: content-box;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  list-style: none;
  overflow-y: scroll;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  white-space: nowrap;
  padding: 5px 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  color: #fff;
  background-color: #216ba5;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: rgba(0, 0, 0, 0);
}

.react-datepicker__week-number {
  color: #ccc;
  width: 1.7rem;
  text-align: center;
  margin: .166rem;
  line-height: 1.7rem;
  display: inline-block;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  background-color: #f0f0f0;
  border-radius: .3rem;
}

.react-datepicker__day-names, .react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-names {
  margin-bottom: -8px;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #000;
  width: 1.7rem;
  text-align: center;
  margin: .166rem;
  line-height: 1.7rem;
  display: inline-block;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range, .react-datepicker__quarter--selected, .react-datepicker__quarter--in-selecting-range, .react-datepicker__quarter--in-range {
  color: #fff;
  background-color: #216ba5;
  border-radius: .3rem;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover, .react-datepicker__quarter--selected:hover, .react-datepicker__quarter--in-selecting-range:hover, .react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled, .react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover, .react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: rgba(0, 0, 0, 0);
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
  cursor: pointer;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
  background-color: #f0f0f0;
  border-radius: .3rem;
}

.react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today, .react-datepicker__year-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted, .react-datepicker__quarter-text--highlighted, .react-datepicker__year-text--highlighted {
  color: #fff;
  background-color: #3dcc4a;
  border-radius: .3rem;
}

.react-datepicker__day--highlighted:hover, .react-datepicker__month-text--highlighted:hover, .react-datepicker__quarter-text--highlighted:hover, .react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1, .react-datepicker__month-text--highlighted-custom-1, .react-datepicker__quarter-text--highlighted-custom-1, .react-datepicker__year-text--highlighted-custom-1 {
  color: #f0f;
}

.react-datepicker__day--highlighted-custom-2, .react-datepicker__month-text--highlighted-custom-2, .react-datepicker__quarter-text--highlighted-custom-2, .react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  color: #fff;
  background-color: #216ba5;
  border-radius: .3rem;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  color: #fff;
  background-color: #2579ba;
  border-radius: .3rem;
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover, .react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range):not(.react-datepicker__month-text--in-range):not(.react-datepicker__quarter-text--in-range):not(.react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range):not(.react-datepicker__month-text--in-range):not(.react-datepicker__quarter-text--in-range):not(.react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range):not(.react-datepicker__month-text--in-range):not(.react-datepicker__quarter-text--in-range):not(.react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range):not(.react-datepicker__month-text--in-range):not(.react-datepicker__quarter-text--in-range):not(.react-datepicker__year-text--in-range) {
  background-color: rgba(33, 107, 165, .5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__month-text--in-selecting-range):not(.react-datepicker__quarter-text--in-selecting-range):not(.react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__month-text--in-selecting-range):not(.react-datepicker__quarter-text--in-selecting-range):not(.react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__month-text--in-selecting-range):not(.react-datepicker__quarter-text--in-selecting-range):not(.react-datepicker__year-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__month-text--in-selecting-range):not(.react-datepicker__quarter-text--in-selecting-range):not(.react-datepicker__year-text--in-selecting-range) {
  color: #000;
  background-color: #f0f0f0;
}

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover, .react-datepicker__month-text--disabled:hover, .react-datepicker__quarter-text--disabled:hover, .react-datepicker__year-text--disabled:hover {
  background-color: rgba(0, 0, 0, 0);
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover, .react-datepicker__quarter-text.react-datepicker__month--selected:hover, .react-datepicker__quarter-text.react-datepicker__month--in-range:hover, .react-datepicker__quarter-text.react-datepicker__quarter--selected:hover, .react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}

.react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  width: 100%;
  display: inline-block;
  position: relative;
}

.react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view {
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: .3rem;
  position: relative;
}

.react-datepicker__year-read-view:hover, .react-datepicker__month-read-view:hover, .react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  top: 0;
  right: -16px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
  width: 50%;
  z-index: 1;
  text-align: center;
  background-color: #f0f0f0;
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  position: absolute;
  top: 30px;
  left: 25%;
}

.react-datepicker__year-dropdown:hover, .react-datepicker__month-dropdown:hover, .react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable, .react-datepicker__month-dropdown--scrollable, .react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 20px;
  display: block;
}

.react-datepicker__year-option:first-of-type, .react-datepicker__month-option:first-of-type, .react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

.react-datepicker__year-option:last-of-type, .react-datepicker__month-option:last-of-type, .react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected, .react-datepicker__month-option--selected, .react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  height: 100%;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  padding: 0 6px 0 0;
  display: table-cell;
  position: absolute;
  top: 0;
  right: 0;
}

.react-datepicker__close-icon:after {
  cursor: pointer;
  color: #fff;
  height: 16px;
  width: 16px;
  text-align: center;
  vertical-align: middle;
  content: "×";
  background-color: #216ba5;
  border-radius: 50%;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  display: table-cell;
}

.react-datepicker__today-button {
  cursor: pointer;
  text-align: center;
  clear: left;
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  padding: 5px 0;
  font-weight: bold;
}

.react-datepicker__portal {
  width: 100vw;
  height: 100vh;
  z-index: 2147480000;
  background-color: rgba(0, 0, 0, .8);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.loader {
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  border: 20px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  margin: 20px auto;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.small_loader {
  width: 35px;
  height: 35px;
  border-width: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.hamburger_icon {
  cursor: pointer;
  z-index: 100;
  border-radius: .25rem;
  margin-right: .5rem;
  padding: .5rem;
  display: inline-block;
}

.hamburger_line {
  width: 35px;
  height: 5px;
  background-color: #fff;
  border-radius: 1rem;
  margin: 6px 0;
  transition: all .4s;
}

.open .bar1 {
  -webkit-transform: rotate(-45deg)translate(-9px, 6px);
  transform: rotate(-45deg)translate(-9px, 6px);
}

.open .bar2 {
  opacity: 0;
}

.open .bar3 {
  -webkit-transform: rotate(45deg)translate(-8px, -8px);
  transform: rotate(45deg)translate(-8px, -8px);
}

@media only screen and (min-width: 1146px) {
  .hamburger_icon {
    display: none;
  }
}

.nav_bar {
  z-index: 27;
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
  align-items: center;
  padding: 1rem;
  display: flex;
}

.dropdown {
  position: relative;
}

.after_home .nav_bar {
  background-color: #676f76;
  padding: 0 .5rem;
}

.after_home .nav_bar .main_text {
  font-size: 20px;
}

.nav_item {
  color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
}

.nav_item:hover {
  cursor: pointer;
  text-decoration: underline;
}

.nav_link {
  text-decoration: none;
}

@media only screen and (min-width: 1146px) {
  .after_home .nav_bar .main_text {
    font-size: 25px;
  }

  .nav_bar {
    flex-direction: row-reverse;
  }
}

.collapsible_menu {
  max-height: 0;
  z-index: 2;
  border-radius: .3rem;
  flex-direction: column;
  padding-left: 3rem;
  padding-right: 3rem;
  transition: all .25s ease-in-out;
  display: flex;
  position: absolute;
  overflow: hidden;
  box-shadow: 3px 4px rgba(39, 3, 0, .184);
}

.collapsible_menu > :not(:last-child) {
  border-bottom: 1px solid #676f76;
  padding-bottom: .25rem;
}

.open > .collapsible_menu {
  max-height: 350px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.finished_open {
  overflow: visible;
}

.main_collapsible_menu {
  background-color: #40454a;
  top: .3rem;
  left: .65rem;
}

@media only screen and (min-width: 1146px) {
  .main_collapsible_menu {
    position: initial;
    max-height: initial;
    width: initial;
    overflow: initial;
    background-color: initial;
    box-shadow: none;
    padding: initial;
    flex-direction: row;
    padding-top: .5rem;
    padding-bottom: .5rem;
    transition: none;
    display: flex;
  }

  .open.main_collapsible_menu {
    height: initial;
    padding: initial;
  }

  .main_collapsible_menu > :not(:last-child) {
    border-bottom: initial;
    padding-bottom: initial;
  }

  .main_collapsible_menu > .nav_item {
    width: initial;
    text-align: initial;
    padding: 1rem;
  }
}

.sub_dropdown {
  position: relative;
}

.sub_collapsible_menu {
  z-index: 100;
  background-color: #4c5157;
  top: 100%;
  left: 0;
}

@media only screen and (min-width: 1146px) {
  .sub_collapsible_menu {
    background-color: #4c5157;
    top: 0;
    left: 100%;
  }

  .sub_dropdown:not(.closed), .sub_dropdown.open {
    background-color: #4c5157;
    box-shadow: 3px 4px rgba(39, 3, 0, .184);
  }

  .sub_collapsible_menu {
    top: 100%;
    right: 0;
    left: inherit;
    background-color: #4c5157;
    border-top-right-radius: 0;
  }
}

.select-search {
  box-sizing: border-box;
  font-family: Nunito Sans, sans-serif;
}

.select-search *, .select-search :after, .select-search :before {
  box-sizing: inherit;
}

.select-search__value {
  z-index: 1;
  position: relative;
}

.select-search__value:after {
  content: "";
  width: 11px;
  height: 11px;
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
}

.select-search__input {
  height: 36px;
  width: 100%;
  text-align: left;
  text-overflow: ellipsis;
  -webkit-appearance: none;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 3px;
  outline: none;
  padding: 0 40px 0 16px;
  font-family: Noto Sans, sans-serif;
  font-size: 14px;
  line-height: 36px;
  display: block;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, .15);
}

.select-search__input::-webkit-input-placeholder {
  color: #000;
}

.select-search__input::-ms-input-placeholder {
  color: #000;
}

.select-search__input::placeholder {
  color: #000;
}

.select-search__input::-webkit-search-decoration, .select-search__input::-webkit-search-cancel-button, .select-search__input::-webkit-search-results-button, .select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

.select-search__select {
  background: #fff;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, .15);
}

.select-search__options {
  margin: 0;
  padding: 0;
  list-style: none;
}

.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

.select-search__option, .select-search__not-found {
  min-height: 36px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  background: #fff;
  border: none;
  outline: none;
  padding: 6px 16px;
  font-family: Noto Sans, sans-serif;
  font-size: 14px;
  display: block;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  color: #fff;
  background: #2fcc8b;
}

.select-search__option.is-highlighted, .select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, .1);
}

.select-search__option.is-highlighted.is-selected, .select-search__option.is-selected:hover {
  color: #fff;
  background: #2eb378;
}

.select-search__group-header {
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
  font-size: 10px;
}

.select-search.is-disabled {
  opacity: .5;
}

.select-search.is-loading .select-search__value:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value:after {
  pointer-events: none;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.select-search--multiple .select-search__input {
  cursor: initial;
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2fcc8b;
}

.select-search:not(.select-search--multiple) .select-search__select {
  z-index: 2;
  max-height: 360px;
  border-radius: 3px;
  margin-top: .5rem;
  margin-bottom: .5rem;
  position: absolute;
  overflow: auto;
}

.select-search--multiple .select-search__select {
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
  position: relative;
  overflow: auto;
}

.select-search__not-found {
  height: auto;
  text-align: center;
  color: #888;
  padding: 16px;
}

.selected_multiple_option_container {
  margin: .25rem 0;
}

.selected_multiple_option {
  white-space: nowrap;
  color: #fff;
  background-color: rgba(47, 204, 139, .667);
  justify-content: space-between;
  align-items: center;
  padding: .75rem .25rem .75rem .75rem;
  display: flex;
}

.selected_multiple_option button {
  background: none;
  border: none;
  margin: 0 .5rem;
  padding: 0;
}

.selected_multiple_option .button_close {
  width: .8rem;
}

.selected_multiple_option .button_close svg {
  stroke: #cc2f2f;
  fill: #cc2f2f;
}

.selected_multiple_option .button_close svg:hover {
  stroke: #bc2c2c;
  fill: #bc2c2c;
}

.selected_multiple_option:nth-child(2n) {
  background-color: #2fcc8b;
}

.selected_option {
  text-overflow: ellipsis;
  overflow: hidden;
}

.select-search, .form_dropdown {
  box-sizing: border-box;
  font-family: Nunito Sans, sans-serif;
}

.select-search *, .form_dropdown *, .select-search :after, .form_dropdown :after, .select-search :before, .form_dropdown :before {
  box-sizing: inherit;
}

.select-search__value, .form_dropdown__value {
  z-index: 1;
  position: relative;
}

.select-search__value:after, .form_dropdown__value:after {
  content: "";
  width: 11px;
  height: 11px;
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
}

.select-search__input, .form_dropdown__input {
  height: 36px;
  width: 100%;
  text-align: left;
  text-overflow: ellipsis;
  -webkit-appearance: none;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 3px;
  outline: none;
  padding: 0 40px 0 16px;
  font-family: Noto Sans, sans-serif;
  font-size: 14px;
  line-height: 36px;
  display: block;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, .15);
}

.select-search__input::-webkit-input-placeholder, .form_dropdown__input::-webkit-input-placeholder {
  color: #000;
}

.select-search__input::-ms-input-placeholder, .form_dropdown__input::-ms-input-placeholder {
  color: #000;
}

.select-search__input::placeholder, .form_dropdown__input::placeholder {
  color: #000;
}

.select-search__input::-webkit-search-decoration, .form_dropdown__input::-webkit-search-decoration, .select-search__input::-webkit-search-cancel-button, .form_dropdown__input::-webkit-search-cancel-button, .select-search__input::-webkit-search-results-button, .form_dropdown__input::-webkit-search-results-button, .select-search__input::-webkit-search-results-decoration, .form_dropdown__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus, .form_dropdown__input:not([readonly]):focus {
  cursor: initial;
}

.select-search__select, .form_dropdown__select {
  background: #fff;
  box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, .15);
}

.select-search__options, .form_dropdown__options {
  margin: 0;
  padding: 0;
  list-style: none;
}

.select-search__row:not(:first-child), .form_dropdown__row:not(:first-child) {
  border-top: 1px solid #eee;
}

.select-search__option, .form_dropdown__option, .select-search__not-found, .form_dropdown__not-found {
  min-height: 36px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  background: #fff;
  border: none;
  outline: none;
  padding: 6px 16px;
  font-family: Noto Sans, sans-serif;
  font-size: 14px;
  display: block;
}

.select-search--multiple .select-search__option, .select-search--multiple .form_dropdown__option {
  height: 48px;
}

.select-search__option.is-selected, .is-selected.form_dropdown__option {
  color: #fff;
  background: #2fcc8b;
}

.select-search__option.is-highlighted, .is-highlighted.form_dropdown__option, .select-search__option:not(.is-selected):hover, .form_dropdown__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, .1);
}

.select-search__option.is-highlighted.is-selected, .is-highlighted.is-selected.form_dropdown__option, .select-search__option.is-selected:hover, .is-selected.form_dropdown__option:hover {
  color: #fff;
  background: #2eb378;
}

.select-search__group-header {
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
  font-size: 10px;
}

.select-search.is-disabled, .is-disabled.form_dropdown {
  opacity: .5;
}

.select-search.is-loading .select-search__value:after, .select-search.is-loading .form_dropdown__value:after, .is-loading.form_dropdown .select-search__value:after, .is-loading.form_dropdown .form_dropdown__value:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input, .select-search:not(.is-disabled) .form_dropdown__input, .form_dropdown:not(.is-disabled) .select-search__input, .form_dropdown:not(.is-disabled) .form_dropdown__input {
  cursor: pointer;
}

.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value:after, .select-search:not(.is-loading):not(.select-search--multiple) .form_dropdown__value:after, .form_dropdown:not(.is-loading):not(.select-search--multiple) .select-search__value:after, .form_dropdown:not(.is-loading):not(.select-search--multiple) .form_dropdown__value:after {
  pointer-events: none;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.select-search--multiple .select-search__input, .select-search--multiple .form_dropdown__input {
  cursor: initial;
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input, .select-search--multiple:not(.select-search--search) .form_dropdown__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover, .select-search:not(.select-search--multiple) .form_dropdown__input:hover, .form_dropdown:not(.select-search--multiple) .select-search__input:hover, .form_dropdown:not(.select-search--multiple) .form_dropdown__input:hover {
  border-color: #2fcc8b;
}

.select-search:not(.select-search--multiple) .select-search__select, .select-search:not(.select-search--multiple) .form_dropdown__select, .form_dropdown:not(.select-search--multiple) .select-search__select, .form_dropdown:not(.select-search--multiple) .form_dropdown__select {
  z-index: 2;
  max-height: 360px;
  border-radius: 3px;
  margin-top: .5rem;
  margin-bottom: .5rem;
  position: absolute;
  overflow: auto;
}

.select-search--multiple .select-search__select, .select-search--multiple .form_dropdown__select {
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
  position: relative;
  overflow: auto;
}

.select-search__not-found, .form_dropdown__not-found {
  height: auto;
  text-align: center;
  color: #888;
  padding: 16px;
}

.main_text, #login_form .form_input, #login_form .form_dropdown__input, #contact_us_form .form_input, #contact_us_form .form_dropdown__input {
  font-size: 20px;
}

@media only screen and (min-width: 450px) {
  .main_text, #login_form .form_input, #login_form .form_dropdown__input, #contact_us_form .form_input, #contact_us_form .form_dropdown__input {
    font-size: 25px;
  }
}

@media only screen and (min-width: 1350px) {
  .main_text, #login_form .form_input, #login_form .form_dropdown__input, #contact_us_form .form_input, #contact_us_form .form_dropdown__input {
    font-size: 30px;
  }
}

.form_input_container {
  width: 100%;
  padding-bottom: .7rem;
}

.form_validation_and_add_container {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
  display: flex;
}

.form_add_new .button {
  color: #fff;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  text-decoration: underline;
}

.form_add_new .button:hover {
  box-shadow: none;
  -webkit-filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .25));
  background-color: rgba(0, 0, 0, 0);
  text-decoration-thickness: 3px;
}

.form_container, .form_dropdown {
  width: calc(100% - 1rem);
}

.form_container div, .form_dropdown div {
  outline-color: rgba(255, 255, 255, .6);
}

.form_input_label {
  font-size: 16px;
}

.form_input, .form_dropdown__input {
  height: 36px;
  background-color: rgba(196, 196, 196, .18);
  border: 0 solid;
  border-bottom: 2px solid #fff;
  padding: .3rem .5rem .2rem;
  font-family: Arial;
  font-size: 17px;
}

.form_input:hover, .form_dropdown__input:hover {
  background-color: rgba(196, 196, 196, .05);
}

.form_input:hover, .form_dropdown__input:hover, .form_input:focus, .form_dropdown__input:focus {
  border-color: #2fcc8b;
}

.form_input:focus, .form_dropdown__input:focus {
  border-color: #2fcc8b;
  border-bottom-style: solid;
  border-bottom-width: 1.5px;
  outline: .5px solid #2fcc8b;
}

.react-datepicker-wrapper {
  width: 100%;
}

textarea {
  resize: none;
  min-height: 120px;
  overflow: hidden;
}

@media only screen and (min-width: 450px) {
  .form_input_container {
    padding-bottom: .8rem;
  }

  .form_container, .form_dropdown {
    width: 20rem;
  }
}

@media only screen and (min-width: 50em) {
  .form_input_container {
    align-items: center;
    display: flex;
  }

  .form_input_label {
    width: 10vw;
    text-align: right;
    padding-right: 2rem;
  }
}

@media only screen and (min-width: 1058px) {
  .form_container, .form_dropdown {
    width: 30vw;
    max-width: 30vw;
  }
}

:focus {
  outline: none;
}

body {
  background-color: #676f76;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

html {
  -webkit-tap-highlight-color: transparent;
  font-family: sans-serif;
}

.app_no_footer {
  min-height: calc(100vh - 53px);
  flex-direction: column;
  display: flex;
}

.hidden {
  display: none;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.text_white, .form_dropdown:not(.is-loading):not(.form_dropdown--multiple) .form_dropdown__value:after, .form_dropdown__input {
  color: #fff;
}

.position_relative {
  position: relative;
}

.main_body {
  background-color: #e8e5e5;
  flex: 1;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
}

::-webkit-input-placeholder {
  color: rgba(255, 255, 255, .6);
}

::-ms-input-placeholder {
  color: rgba(255, 255, 255, .6);
}

::placeholder {
  color: rgba(255, 255, 255, .6);
}

:-ms-input-placeholder {
  color: rgba(255, 255, 255, .6);
}

::-moz-placeholder {
  color: rgba(255, 255, 255, .6);
}

.summary_container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #b8b8b8;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #7c7c7c;
}

.form_dropdown {
  box-sizing: initial;
  font-family: initial;
}

.form_dropdown__value:after {
  right: 1rem;
}

.form_dropdown__input {
  width: calc(100% - 3rem);
  padding-right: 2.5rem;
  font-size: 17px;
}

.form_dropdown__input::-webkit-input-placeholder {
  color: #fff;
}

.form_dropdown__input::-ms-input-placeholder {
  color: #fff;
}

.form_dropdown__input::placeholder {
  color: #fff;
}

.form_dropdown__row {
  display: flex;
}

.form_dropdown--multiple .form_dropdown__option {
  height: 48px;
}

.form_dropdown__option.is-selected {
  color: #fff;
  background: #2fcc8b;
}

.form_dropdown__option.is-highlighted, .form_dropdown__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, .1);
}

.form_dropdown__option.is-highlighted.is-selected, .form_dropdown__option.is-selected:hover {
  color: #fff;
  background: #2eb378;
}

.form_dropdown__group-header {
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
  font-size: 10px;
}

.form_dropdown.is-disabled {
  opacity: .5;
}

.form_dropdown.is-loading .form_dropdown__value:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.form_dropdown:not(.is-disabled) .form_dropdown__input {
  cursor: pointer;
}

.form_dropdown--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.form_dropdown:not(.is-loading):not(.form_dropdown--multiple) .form_dropdown__value:after {
  pointer-events: none;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.form_dropdown--multiple .form_dropdown__input {
  cursor: initial;
  border-radius: 3px 3px 0 0;
}

.form_dropdown--multiple:not(.form_dropdown--search) .form_dropdown__input {
  cursor: default;
}

.form_dropdown:not(.form_dropdown--multiple) .form_dropdown__input:hover {
  border-color: #2fcc8b;
}

.form_dropdown:not(.form_dropdown--multiple) .form_dropdown__select {
  z-index: 2;
  max-height: 360px;
  border-radius: 3px;
  margin-top: .5rem;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
}

.form_dropdown--multiple .form_dropdown__select {
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.form_dropdown__not-found {
  height: auto;
  text-align: center;
  color: #888;
  padding: 16px;
}

/*# sourceMappingURL=auth.967654d1.css.map */
