
@import "../../../constants";

.sub_dropdown {
    position: relative;
}

.sub_collapsible_menu {
    background-color: #4c5157;
    top: 100%;
    left: 0;
    z-index: 100;
}

@media only screen and (min-width:$menu_collapse_width) {
    .sub_collapsible_menu {
        background-color: #4c5157;
        top: 0;
        left: 100%;
    }
    .sub_dropdown:not(.closed) {
        background-color: #4c5157;
        box-shadow: 3px 4px #2703002f;
    }
    .sub_dropdown.open {
        background-color: #4c5157;
        box-shadow: 3px 4px #2703002f;
    }
    .sub_collapsible_menu {
        background-color: #4c5157;
        border-top-right-radius: 0;
        top: 100%;
        right: 0;
        left: inherit;
    }
}