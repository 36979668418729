
.loader {
    box-sizing: border-box;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 20px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}
.small_loader {
    margin-top: 10px;
    margin-bottom: 10px;
    border-width: 8px;
    width: 35px;
    height: 35px;
}
/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}