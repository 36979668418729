@import "./constants";


*:focus {
    outline: none;
}
body {
    background-color: $main_color;
    margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
html {
    font-family: sans-serif;
    -webkit-tap-highlight-color: transparent;
}
.app_no_footer {
    min-height: calc(100vh - 53px);
    display: flex;
    flex-direction: column;
}

.hidden {
    display: none;
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}
.text_white {
    color: white;
}
.position_relative {
    position: relative;
}
.main_body {
    flex: 1;
    background-color: $secondary_color;
    box-shadow: $shadow_props;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(255, 255, 255, 0.6);
} 
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, 0.6);
} 
::-ms-input-placeholder { /* Microsoft Edge */
    color: rgba(255, 255, 255, 0.6);
}
.summary_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

::-webkit-scrollbar{
    width:15px;
}
::-webkit-scrollbar-track {
    background: #ffffff; 
}
::-webkit-scrollbar-thumb {
    background: rgb(184, 184, 184); 
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: rgb(124, 124, 124); 
}