@import "../../../constants";

.main_collapsible_menu {
    background-color: #40454a;
    top: 0.3rem;
    left: 0.65rem;
}

@media only screen and (min-width:$menu_collapse_width) {
    .main_collapsible_menu {
        position: initial;
        max-height: initial;
        width: initial;
        overflow: initial;
        display: flex;
        flex-direction: row;
        background-color: initial;
        transition: none;
        box-shadow: none;
        padding: initial;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    .open.main_collapsible_menu {
        height: initial;
        padding: initial;
    }
    .main_collapsible_menu > :not(:last-child) {
        border-bottom: initial;
        padding-bottom: initial;
    }
    .main_collapsible_menu > .nav_item {
        padding: 1rem;
        width: initial;
        text-align: initial;
    }
}