
.main_text {
    font-size: 20px;
}
@media only screen and (min-width:450px) {
    .main_text {
        font-size: 25px;
    }
}
@media only screen and (min-width:1350px) {
    .main_text {
        font-size: 30px;
    }
}