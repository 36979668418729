@import "../../constants";

.nav_bar {
    display: flex;
    padding: 1rem;
    z-index: 27;
    filter: $shadow;
    align-items: center;
}

// TODO - See if this is necessary, if so, where should it be put?
.dropdown {
    position: relative;
}

.after_home {
    .nav_bar {
        background-color: $main_color;
        padding: 0;
        padding-left: .5rem;
        padding-right: .5rem;
        .main_text {
            font-size: 20px;
        }
    }
}

.nav_item {
    padding-left: 1rem;
    padding-right: 1rem;
    color: white;
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.nav_link {
    text-decoration: none;
}

@media only screen and (min-width:$menu_collapse_width) {
    .after_home {
        .nav_bar {
            .main_text {
                font-size: 25px;
            }
        }
    }
    .nav_bar {
        flex-direction: row-reverse;
    }
}
