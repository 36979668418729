@import "../../../constants";

.collapsible_menu {
    position: absolute;
    max-height: 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: all 0.25s ease-in-out;
    border-radius: 0.3rem;
    box-shadow: 3px 4px #2703002f;
    padding-left: 3rem;
    padding-right: 3rem;
    z-index: 2;
}

.collapsible_menu > :not(:last-child) {
    border-bottom: $main_color 1px solid;
    padding-bottom: 0.25rem;
}

.open > .collapsible_menu {
    max-height: 350px;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.finished_open {
    overflow: visible;
}
