.selected_multiple_option_container {
    margin: 0.25rem 0rem;
}

$close_color: #cc2f2f;
$close_hover_color: #bc2c2c;

.selected_multiple_option {
    white-space: nowrap;
    padding: 0.75rem 0.25rem 0.75rem 0.75rem;
    color: white;
    background-color: #2fcc8baa;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        padding: 0;
        background: transparent;
        border: none;
        margin: 0 .5rem 0 .5rem;
    }
    .button_close {
        width: .8rem;
        svg {
            stroke: $close_color;
            fill: $close_color;
            &:hover {
                stroke: $close_hover_color;
                fill: $close_hover_color;
            }
        }
    }
}

.selected_multiple_option:nth-child(even) {
    background-color: #2fcc8b;

}

.selected_option {
    text-overflow: ellipsis;
    overflow: hidden;
}
